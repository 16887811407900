import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'; 

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    tagline
                    copyright
                    footer
                }
            }
        }
    `);
    const {title, tagline, footer} = data.site.siteMetadata;
    return (
        <div className="flex-row justify-content-center footer">
            <div className="col">
                <div className="col-12 text-center">
                    <h3>{title}</h3>
                    <div className="poweredby">{tagline}</div>
                </div>
            </div>
            <div className="col bg-main">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="copyright p-2 p-md-3"><span dangerouslySetInnerHTML={{ __html: footer }} /></div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}