import React from 'react';
import Footer from './Footer';
import Header from './Header';


export default function Layout({children}) {
    return (
        <div>
            
            <div className="container-xl">
                <Header />
                <div className="row">
                    <div className="col-12 col-md-8 col-xl-7 br-right pl-cc-13">
                        {children}
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 pr-0 pl-cc-3">

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}