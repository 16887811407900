import React from 'react';
import Img from "gatsby-image";

export default function FeaturedImage({image}) {
    return (
        <div className="featured-image">
            <Img fluid={image.localFile.childImageSharp.fluid} alt="" />
            {image.altText !== '' ? <span className="image-description">{image.altText}</span> : ''}
        </div>
    );
}