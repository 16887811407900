import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, EmailIcon, EmailShareButton } from 'react-share';

export default function Share(props) {
    const { url, title, source } = props;
    return (
        <span className="share">
            <FacebookShareButton url={url} quote={title} className="pr-1">
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <LinkedinShareButton source={source} url={url} className="pr-1">
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton url={url} title={title} className="pr-1">
                <TwitterIcon size={32} round={true}  />
            </TwitterShareButton>
            <EmailShareButton url={url} subject={title}>
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
        </span>
    );
}